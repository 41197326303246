'use client';

import type { PropsWithChildren } from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import weekDay from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import '@glideapps/glide-data-grid/dist/index.css';
import '@/global.css';
import { appTheme } from '@/theme';

import AppSnackbarProvider from './snackbar-provider';

/**
 * Client library setup
 */
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);
dayjs.extend(quarterOfYear);
dayjs.extend(weekDay);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const UiProvider = ({ children }: PropsWithChildren) => (
  <MuiThemeProvider theme={appTheme}>
    <AppSnackbarProvider>
      <CssBaseline />
      {children}
    </AppSnackbarProvider>
  </MuiThemeProvider>
);
