'use client';

import { I18nextProvider } from 'react-i18next';

import { useIsomorphicTranslation } from '../i18n/client';
import { FALLBACK_LNG, Lang } from '../i18n/settings';

const Provider = ({
  children,
  lang = FALLBACK_LNG,
}: {
  children: React.ReactNode;
  lang?: Lang;
}) => {
  const { i18n: i18nClient } = useIsomorphicTranslation(lang);
  return <I18nextProvider i18n={i18nClient}>{children}</I18nextProvider>;
};

export default Provider;
