import type { Components, Theme } from '@mui/material/styles';

const PopoverTheme: Components<Omit<Theme, 'components'>> = {
  MuiPopover: {
    styleOverrides: {
      root: {
        overflow: 'scroll',
      },
      paper: ({ theme }) => ({
        padding: '16px 8px',
        background: theme.palette.neutralV2[8],
        boxShadow: theme.shadows[1],
        borderRadius: '10px',
        overflowY: 'auto',
        ['&::-webkit-scrollbar']: {
          width: '4px',
          borderRadius: ' 8px',
          backgroundColor: theme.palette.neutral[6],
        },
        ['&::-webkit-scrollbar-thumb']: {
          borderRadius: '4px',
          backgroundColor: theme.palette.neutral[4],
        },
      }),
    },
  },
};

export default PopoverTheme;
