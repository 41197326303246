import type { ReportTableData } from './feature-report/types';
import type { MaybeErrorLocale } from '@/common.type';

import { AxiosError } from 'axios';

import { ApiServerError } from '@/models/common';

export class ApiError extends AxiosError {
  private __apiError: ApiServerError;
  private __statusCode: number;
  private __originError: any;

  constructor(
    originError: any,
    e: ApiServerError,
    statusCode = 500,
    msg = '[api-error]',
  ) {
    super(e.error_message || msg);
    this.__apiError = e;
    this.__statusCode = statusCode;
    this.__originError = originError;
  }

  public get originError() {
    return this.__originError;
  }

  public get statusCode() {
    return this.__statusCode;
  }

  public get apiError() {
    return this.__apiError;
  }

  public get errorCode() {
    return (
      (this.__apiError.error_code?.toString() as MaybeErrorLocale) || 'unknown'
    );
  }

  public get errorMessage() {
    return this.__apiError.error_message;
  }

  isUserError() {
    return this.__statusCode >= 400 && this.__statusCode < 500;
  }
}

export interface Issue<Data, RetryParams> {
  data: Data;
  retry: (
    params: RetryParams,
  ) => Promise<ReportTableData | null> | ReportTableData | null;
  checkIfFixed: (response: ReportTableData) => Promise<boolean> | boolean;
}

export type FxIssueRetryParam = {
  columnId: string;
  fixed: { formula: string };
};

export type FxIssueData = {
  columnId: string;
  message: string;
};

export interface FxIssue extends Issue<FxIssueData, FxIssueRetryParam> {}
