import React, { ReactNode } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';
import { CustomContentProps } from 'notistack';

interface SyncStatusSnackBar extends CustomContentProps {
  icon?: React.ReactNode;
  tooltipMessage?: ReactNode;
}

export const SyncStateSnackBar = React.forwardRef(function SyncStatusSnackBar(
  { id, message, icon, action, tooltipMessage }: SyncStatusSnackBar,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <Tooltip arrow title={tooltipMessage}>
      <Box
        ref={ref}
        bgcolor="neutralV2.1"
        borderRadius="6px"
        color="neutralV2.0"
        border="1px solid"
        borderColor="neutralV2.2"
        boxShadow={4}
        padding="6px"
        gap="8px"
        width="fit-content"
        display="flex"
        alignItems="center"
      >
        {icon}
        <Typography variant="subhead6" color="neutralV2.8">
          {message}
        </Typography>
        {action && typeof action === 'function' ? action(id) : action}
      </Box>
    </Tooltip>
  );
});
