'use client';

import { PropsWithChildren } from 'react';

import { SnackbarOrigin, SnackbarProvider } from 'notistack';

import { AppSnackBar } from '@/components/SnackBar';
import { SyncStateSnackBar } from '@/components/SyncStatusSnackBar';

const DEFAULT_SNACKBAR_TIMEOUT_IN_MS = 1000 * 5; // NOTE: 5 seconds
const SNACKBAR_COMPONENTS = {
  custom: AppSnackBar,
  syncStatus: SyncStateSnackBar,
};
const SNACKBAR_ANCHOR_ORIGIN: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top',
};

const AppSnackbarProvider = ({ children }: PropsWithChildren) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={SNACKBAR_ANCHOR_ORIGIN}
      Components={SNACKBAR_COMPONENTS}
      autoHideDuration={DEFAULT_SNACKBAR_TIMEOUT_IN_MS}
      preventDuplicate
      //
    >
      {children}
    </SnackbarProvider>
  );
};

export default AppSnackbarProvider;
