import appConfig from '@/constants/configs';

export const FALLBACK_LNG = 'en';

export const SUPPORTED_LANGUAGES = [FALLBACK_LNG, 'ko'] as const;

export type Lang = (typeof SUPPORTED_LANGUAGES)[number];

const DEFAULT_NS = 'common';

export const LOCALE_COOKIE_NAME = appConfig.USER_PREFERRED_LANGUAGE_COOKIE_NAME;

export function getOptions(lng = FALLBACK_LNG, ns = DEFAULT_NS) {
  return {
    // debug: true,
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: FALLBACK_LNG,
    lng,
    fallbackNS: DEFAULT_NS,
    defaultNS: DEFAULT_NS,
    ns,
  };
}
