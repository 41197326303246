'use client';

import { PropsWithChildren, useState } from 'react';

import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from '@tanstack/react-query';

import { isApiError } from '@/utils/api-error';

const DEFAULT_QUERY_STALE_TIME_IN_MS = 1000 * 20; // NOTE: 20 seconds
// const DEFAULT_QUERY_RETRIES = 0; // NOTE: 1 time
const DEFAULT_QUERY_RETRY_DELAY_IN_MS = 1000; // NOTE: 1 seconds
const HTTP_UNAUTHORIZED_CODE = 401;
const HTTP_FORBIDDEN_CODE = 403;

const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_STALE_TIME_IN_MS,
      retry: false,
      retryDelay: DEFAULT_QUERY_RETRY_DELAY_IN_MS,
      useErrorBoundary: (error: any) => {
        // NOTE: throw error into nearest error boundary
        return (
          isApiError(error) &&
          (error.statusCode === HTTP_FORBIDDEN_CODE ||
            error.statusCode === HTTP_UNAUTHORIZED_CODE)
        );
      },
    },
  },
};

export default function AppQueryProvider({ children }: PropsWithChildren) {
  const [queryClient] = useState(() => new QueryClient(QUERY_CLIENT_CONFIG));

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
